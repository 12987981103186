import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CustomTabs from "../components/CustomTabs";
import CustomChart from "../components/CustomChart";
import CustomTabSwitcher from "../components/Switcher";
import { UserContext } from "../Context/UserContext";
import { ReferralsContext } from "../Context/ReferralsContext";
const AppContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const BalanceInfo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
`;

const BalanceSection = styled.div`
  text-align: center;
`;

const BalanceAmount = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const CustomButton = styled.button`
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ChartContainer = styled.div`
  margin: 30px 0;
`;

const Agent_page = ({ telegramId }) => {
  return (
    <AppContainer>
      <BalanceInfo>
        <BalanceSection>
          <BalanceAmount>$159.21</BalanceAmount>
          <span>Available</span>
        </BalanceSection>
        <CustomButton>Claim</CustomButton>
        <BalanceSection>
          <BalanceAmount>$720</BalanceAmount>
          <span>Total earned</span>
        </BalanceSection>
      </BalanceInfo>

      <ChartContainer>
        <CustomChart />
      </ChartContainer>

      <CustomTabSwitcher telegramId={telegramId} />
    </AppContainer>
  );
};

export default Agent_page;
