import React, { createContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL, generateHash } from "../Helpers/Api";

export const ReferralsContext = createContext();

export const ReferralsProvider = ({ children }) => {
  const [referrals, setReferrals] = useState(null); // Состояние для referrals
  const [defaultRefs, setDefaultRef] = useState(null);

  const fetchReferrals = async (telegramId) => {
    const { signature, timestamp } = await generateHash(telegramId);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/get_agents/`,
        { user_id: telegramId },
        {
          headers: {
            "X-Signature": signature, // Підпис
            "X-Timestamp": timestamp, // Час
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setReferrals(response.data);
        setDefaultRef(response.data);
        console.log("Fetched Referrals:", response.data);
        window.Telegram.WebApp.HapticFeedback.impactOccurred("heavy");
        return response.data;
      } else {
        console.error("Error fetching Referrals:", response.data.message);
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch Referrals:", error);
      return null;
    }
  };

  return (
    <ReferralsContext.Provider
      value={{
        referrals,
        defaultRefs,
        setDefaultRef,
        setReferrals,
        fetchReferrals,
      }}
    >
      {children}
    </ReferralsContext.Provider>
  );
};
