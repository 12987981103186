// src/App.js
import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import HomeRedesign from "./pages/new_Home";
import Settings from "./pages/Settings";
import Agent_page from "./pages/agent";
import { UserProvider } from "./Context/UserContext";
import { ContractsProvider } from "./Context/ContractsContext";
import { TasksProvider } from "./Context/TasksContext";
import { ChannelsProvider } from "./Context/ChannelsContext";
import { ReferralsProvider } from "./Context/ReferralsContext";
import { API_BASE_URL, generateHash } from "./Helpers/Api";
import PreLoad from "./pages/LoadingPage";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./themes"; // Adjust the path as necessary
import Sorry from "./pages/Sorry";
const App = () => {
  const [userData, setUserData] = useState(null); // Ініціалізуємо userData як null
  const [startappParams, setStartappParams] = useState("");
  const [reg_date, setRegDate] = useState(null);
  const location = useLocation();
  const showBottomNavbar =
    location.pathname !== "/welcome" &&
    location.pathname !== "/preload" &&
    location.pathname !== "/proxy";
  const hasFetchedData = useRef(false); // Використовуємо useRef, щоб уникнути повторних запитів

  const checkUserInApp = async (startappParams, user_id, bot_type) => {
    const [contractType, userPart] = startappParams.split("_");
    const formattedContractType = `${contractType}_${userPart}`; // Формуємо новий рядок з двох елементів

    const { signature, timestamp } = await generateHash(user_id);
    try {
      const response = await fetch(
        `${API_BASE_URL}/check_user/${bot_type}/`, // Використовуємо оновлений URL
        {
          method: "POST",
          headers: {
            "X-Signature": signature, // Підпис
            "X-Timestamp": timestamp, // Час
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id, startappParams }),
        }
      );
      const data = await response.json();
      if (data.redirectUrl) {
        if (/Android|iPhone/i.test(navigator.userAgent)) {
          window.location.href = data.redirectUrl;
          window.Telegram.WebApp.close();
        } else {
          window.open(data.redirectUrl, "_blank");
          window.Telegram.WebApp.close();
        }
      } else {
        window.Telegram.WebApp.close();
        setUserData(null);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const initializeTelegramWebApp = async () => {
      if (hasFetchedData.current) return; // Якщо дані вже завантажені, виходимо

      if (window.Telegram && window.Telegram.WebApp) {
        const webAppData = window.Telegram.WebApp.initDataUnsafe;

        const user = webAppData.user;
        const urlParams = new URLSearchParams(window.location.search);
        const startappParams = urlParams.get("tgWebAppStartParam");
        setStartappParams(startappParams);

        if (user) {
          setUserData(user);
          const randomDate = new Date(
            Date.UTC(2019, 0, 31) +
              Math.random() * (Date.UTC(2024, 6, 10) - Date.UTC(2019, 0, 31))
          ).toISOString();
          setRegDate(randomDate);
        } else {
          const defaultUser = {
            username: "bogdan_krvsk",
            first_name: "bogdan_krvsk 🐵",
            id: 874423521,
            is_premium: true,
          };
          setUserData(defaultUser);
          const randomDate = new Date(
            Date.UTC(2019, 0, 31) +
              Math.random() * (Date.UTC(2024, 6, 10) - Date.UTC(2019, 0, 31))
          ).toISOString();
          setRegDate(randomDate);
        }

        hasFetchedData.current = true; // Позначаємо, що запит був виконаний
      }
    };

    initializeTelegramWebApp();
  }, []);
  useEffect(() => {
    const handleProxyBacklagan = async () => {
      if (
        location.pathname === "/proxy" &&
        location.search.includes("app=backlagan")
      ) {
        const urlParams = new URLSearchParams(window.location.search);
        const startappParams = urlParams.get("tgWebAppStartParam");

        if (startappParams && userData && userData.id) {
          await checkUserInApp(startappParams, userData.id, "baklazhan");
        }
      }
      if (
        location.pathname === "/proxy" &&
        location.search.includes("app=wap")
      ) {
        const urlParams = new URLSearchParams(window.location.search);
        const startappParams = urlParams.get("tgWebAppStartParam");

        if (startappParams && userData && userData.id) {
          await checkUserInApp(startappParams, userData.id, "wap");
        }
      }
      if (
        location.pathname === "/proxy" &&
        location.search.includes("app=SecretPadBot")
      ) {
        const urlParams = new URLSearchParams(window.location.search);
        const startappParams = urlParams.get("tgWebAppStartParam");

        if (startappParams && userData && userData.id) {
          await checkUserInApp(startappParams, userData.id, "SecretPadBot");
        }
      }
    };

    handleProxyBacklagan();
  }, [location, userData]);

  if (!userData) {
    return <div style={{ color: "black" }}>Loading...</div>;
  }

  return (
    <>
      <UserProvider userData={userData}>
        <Routes>
          <Route path="/home" element={<HomeRedesign />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/proxy"
            element={<div>Close this window...</div>} // Placeholder UI
          />

          <Route
            path="/preload"
            element={<PreLoad telegramId={userData.id} />}
          />
          <Route path="*" element={<Navigate to="/preload" />} />
          <Route path="/sorry" element={<Sorry />} />
        </Routes>
      </UserProvider>
    </>
  );
};

function AppWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <ChannelsProvider>
        <TasksProvider>
          <ContractsProvider>
            <ReferralsProvider>
              <Router>
                <App />
              </Router>
            </ReferralsProvider>
          </ContractsProvider>
        </TasksProvider>
      </ChannelsProvider>
    </ThemeProvider>
  );
}

export default AppWrapper;
