import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CustomTabs from "../components/CustomTabs";
import CustomChart from "../components/CustomChart";
import CustomTabSwitcher from "../components/Switcher";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"; // For the earnings icon
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ContractsContext } from "../Context/ContractsContext";
import { ReferralsContext } from "../Context/ReferralsContext";
const AppContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const CopyAlert = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 0px;
  border-bottom: 1px solid #eee;
  width: 75%;
  font-family: "SF normal";
  font-weight: bold;
  font-size: medium;
  color: #444;
  position: absolute;
  bottom: 82px;
  left: 15%;
  right: 0;
  z-index: 5;
`;

const BalanceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 20px 20px;
`;
const BalanceInfo2 = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px;
`;
const BalanceSection = styled.div`
  text-align: center;
  background: #60c65736;
  padding: 15px;
  padding-left: 25px !important;
  border-radius: 100px 0 0 100px;
`;

const BalanceSection2 = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BalanceAmount = styled.p`
  font-family: "SF", sans-serif !important;
  font-size: 28px;
  font-weight: 800;
  margin: 0;
`;

const CustomButton = styled.button`
  border-radius: 20px;
  background-color: #60c657;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 2s ease;
  &:active {
    background-color: #50a649;
  }
`;

const ChartContainer = styled.div`
  margin: 30px 0;
`;

const HomeRedesign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, referrals } = location.state || {};
  const { fetchUserContracts } = useContext(ContractsContext);
  const { defaultRefs, setReferrals } = useContext(ReferralsContext);
  const [alert, setAlert] = useState(false);
  const handleBack = () => {
    setReferrals(defaultRefs);
    navigate("/home", {
      state: { user: user, referrals: defaultRefs, activeTab: "referrals" }, // Передаем данные правильно
    });
  };
  useEffect(() => {
    const loadData = async () => {
      fetchUserContracts(referrals.user_id);
    };
    if (user.user_id != referrals.user_id) {
      window.Telegram.WebApp.BackButton.show().onClick(handleBack);
    }
    loadData();
  }, [referrals]);
  if (!user || !referrals) {
    return <div>Loading...</div>; // Если данные не переданы, показываем сообщение
  }
  return (
    <AppContainer>
      {user.user_id === referrals.user_id ? (
        <>
          {" "}
          <BalanceInfo>
            <CustomButton
              onClick={() => {
                setAlert(true);
                setTimeout(() => setAlert(false), 5000);
              }}
            >
              Claim
            </CustomButton>

            <BalanceSection>
              <span style={{ color: "#aaa" }}>Total earned</span>
              <BalanceAmount>${user.balance}</BalanceAmount>
            </BalanceSection>
          </BalanceInfo>
          <CustomTabSwitcher user={user} referrals={referrals} />
        </>
      ) : (
        <>
          {" "}
          <BalanceInfo2>
            <BalanceSection2>
              {referrals.avatar ? (
                <img
                  src={
                    referrals.avatar
                      ? `data:image/jpeg;base64,${referrals.avatar}`
                      : "default_avatar_path"
                  } // Проверяем наличие аватара
                  alt={referrals.username}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: "50%",
                    marginRight: "10%",
                  }}
                />
              ) : (
                <div
                  className="_media_iud9y_8"
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginRight: 10,
                  }}
                >
                  <img
                    style={{ borderRadius: "50%", width: 50 }}
                    className="_avatar_iud9y_19"
                    src={`https://ui-avatars.com/api/?name=${referrals.username}&background=random&color=fff`}
                    loading="lazy"
                    alt="Avatar"
                  />
                </div>
              )}
              <div>
                <BalanceAmount>{referrals?.username}</BalanceAmount>
                {referrals.referred_by == user.user_id ? (
                  <span>Agent level 1</span>
                ) : (
                  <span>Agent level 2</span>
                )}
              </div>
            </BalanceSection2>
          </BalanceInfo2>
          <CustomTabSwitcher user={user} referrals={referrals} />
        </>
      )}
      {alert && (
        <CopyAlert>
          Rewards claim will be available after the campaign is finished!
        </CopyAlert>
      )}
    </AppContainer>
  );
};

export default HomeRedesign;
