// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL, generateHash } from "../Helpers/Api";
// Create a context for user
export const UserContext = createContext();

export const UserProvider = ({ children, userData }) => {
  // Initialize state for user with default values
  const [user, setUser] = useState({});

  const fetchUser = async (telegramId) => {
    try {
      const { signature, timestamp } = await generateHash(telegramId);
      const response = await axios.post(
        `${API_BASE_URL}/get_user/`,
        { user_id: telegramId },
        {
          headers: {
            "X-Signature": signature, // Підпис
            "X-Timestamp": timestamp, // Час
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 && response.data) {
        const user = response.data; // Отримуємо дані користувача з відповіді
        setUser(user); // Оновлюємо контекст користувача
        return user; // Повертаємо користувача
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
