// src/pages/Settings.js
import React from 'react';

const Settings = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1>Settings</h1>
            <p>Adjust your preferences and manage referral links.</p>
        </div>
    );
};

export default Settings;
