import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL, generateHash } from "../Helpers/Api";
// Створюємо контекст ChannelsContext
export const ChannelsContext = createContext();

// Провайдер контексту
export const ChannelsProvider = ({ children }) => {
  const [Channels, setChannels] = useState(null); // Статус племені користувача
  const [ChannelsLoading, setChannelsLoading] = useState(false); // Стан завантаження даних

  // Функція для отримання даних про плем'я
  const fetchChannels = async (telegram_id) => {
    try {
      setChannelsLoading(true);
      const { signature, timestamp } = await generateHash(telegram_id);
      const response = await axios.post(
        `${API_BASE_URL}/get_channels/`,
        {
          user_id: telegram_id,
        },
        {
          headers: {
            "X-Signature": signature, // Підпис
            "X-Timestamp": timestamp, // Час
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setChannels(response.data.Channels);
      } else {
        setChannels(null);
      }
      return response;
    } catch (error) {
    } finally {
      setChannelsLoading(false);
    }
  };

  return (
    <ChannelsContext.Provider
      value={{
        Channels,
        ChannelsLoading,
        fetchChannels,
      }}
    >
      {children}
    </ChannelsContext.Provider>
  );
};
