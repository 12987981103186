import React from 'react';
import styled from 'styled-components';

const AgentItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const AgentInfo = styled.div`
  flex-grow: 1;
  padding-left: 10px;
`;

const EarningsInfo = styled.div`
  font-weight: bold;
`;

const AgentItem = ({ name, referrals, earnings }) => {
    return (
        <AgentItemContainer>
            <Avatar alt={name} src={`https://i.pravatar.cc/150?u=${name}`} />
            <AgentInfo>
                <p>{name}</p>
                <span>{referrals}</span>
            </AgentInfo>
            <EarningsInfo>{earnings}</EarningsInfo>
        </AgentItemContainer>
    );
};

export default AgentItem;
