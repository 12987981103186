import React from 'react';
import styled from 'styled-components';

const ReferralsItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const ReferralsInfo = styled.div`
  flex-grow: 1;
  padding-left: 10px;
`;

const PushButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ReferralsItem = ({ name, referrals, earnings }) => {
    return (
        <ReferralsItemContainer>
            <Avatar alt={name} src={`https://i.pravatar.cc/150?u=${name}`} />
            <ReferralsInfo>
                <p>{referrals}</p>
                <span>{earnings}</span>
            </ReferralsInfo>
            <PushButton>Push</PushButton>
        </ReferralsItemContainer>
    );
};

export default ReferralsItem;
