import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LinkIcon from "@mui/icons-material/Link";
import PersonIcon from "@mui/icons-material/Person"; // For the user icon
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"; // For the earnings icon
import PaidIcon from "@mui/icons-material/Paid";
import { UserContext } from "../Context/UserContext";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import { ReferralsContext } from "../Context/ReferralsContext";
import { useLocation, useNavigate } from "react-router-dom";
import { transition } from "d3";
import { ContractsContext } from "../Context/ContractsContext";
import axios from "axios";
import { API_BASE_URL } from "../Helpers/Api";
import { Spinner } from "../icons/Spinner";

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 95vw;
  padding: 2px;
  background: #f5f5f5;
  border-radius: 30px;
  margin: 20px 10px;
`;

const Tab = styled.div`
  width: 50%;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  border-radius: 30px;
  background-color: ${({ active }) => (active ? "#fff" : "#f5f5f5")};
  color: ${({ active }) => (active ? "#63C558" : "#b0b0b0")};
  font-weight: bold;
`;

const AgentsList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AgentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 0px;
  border-bottom: 1px solid #eee;
  width: 93vw;
  font-weight: bold;
  color: #444;
`;

const CopyAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 0px;
  border-bottom: 1px solid #eee;
  width: 75%;
  font-weight: bold;
  font-size: medium;
  color: #444;
  position: absolute;
  bottom: 82px;
  left: 15%;
  right: 0;
  z-index: 5;
`;

const AgentInfo = styled.div`
  display: flex;
  align-items: center;
`;

const AgentName = styled.div`
  margin-left: 10px;
`;

const CustomButton = styled.div`
  border-radius: 20px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const LevelRefInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  color: #b0b0b0;
`;

const LevelInfo = styled.div`
  margin-top: 5px;
  font-size: 0.85rem;
`;

const Earnings = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #63c558;
`;

const RefLinkButton = styled.button`
  width: 95vw;
  padding: 12px 15px;
  background-color: #63c558;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-color: unset;
  border-radius: 100px;
  margin-top: 10px;
  margin-bottom: 40px;
  cursor: pointer;
`;

export default function CustomTabSwitcher({ user, referrals }) {
  const userFetchedRef = useRef(false);
  const { contracts, isContractLoading } = useContext(ContractsContext);
  const [activeTab, setActiveTab] = useState("referrals");
  const navigate = useNavigate();
  const [copyMessage, setCopyMessage] = useState(false);
  const location = useLocation();

  const filterAgentReferrals = (agent) => {
    if (!referrals || !referrals.agents) return null;

    // Фильтруем рефералов, чье поле referred_by совпадает с user_id агента
    const agentReferrals = referrals.agents.filter(
      (ref) => ref.referred_by === agent.user_id
    );
    return {
      user_id: agent.user_id,
      username: agent.username,
      lvl2: agent.lvl2,
      lvl3: agent.lvl3,
      total_refs: agent.total_refs,
      level: agent.level,
      balance: agent.balance,
      avatar: agent.avatar,
      referred_by: agent.referred_by,
      agents: agentReferrals, // Вложенные отфильтрованные рефералы
    };
  };

  useEffect(() => {
    if (!user.user_id != referrals.user_id) {
      setActiveTab(location.state["activeTab"] || { referrals });
    }
    if (!referrals?.agents) {
      setActiveTab("referrals");
    }
  }, [referrals]);
  return (
    <div style={{ display: "block", alignSelf: "center" }}>
      <TabContainer>
        <Tab
          active={activeTab === "referrals"}
          onClick={() => setActiveTab("referrals")}
        >
          Referrals{" "}
        </Tab>
        {referrals?.agents && (
          <Tab
            active={activeTab === "agents"}
            onClick={() => setActiveTab("agents")}
          >
            Agents{" "}
            <span
              style={{
                fontSize: "0.8rem",
                backgroundColor: activeTab === "agents" ? "#63C558" : "#e0e0e0",
                color: "white",
                padding: "2px 9px",
                borderRadius: "100px",
                marginLeft: "5px",
              }}
            >
              {referrals.total_refs}
            </span>
          </Tab>
        )}
      </TabContainer>

      {activeTab === "agents" && (
        <AgentsList>
          {referrals?.agents.map((agent, index) => {
            // Если agent.level === 2 и referrals.user_id === user.user_id
            const isFilteredAgent =
              referrals.user_id === user.user_id && agent.level === 2;
            const isFilteredAgent2 =
              referrals.user_id != user.user_id && agent.level === 2;
            const isFilteredAgent3 = (referrals.level = 2);
            // Если агент должен быть скрыт, пропускаем его
            if (isFilteredAgent) return null;
            return (
              <AgentCard
                key={index}
                onClick={() => {
                  const agentReferrals = filterAgentReferrals(agent); // Получаем рефералов агента
                  navigate("/home", {
                    state: {
                      user: user,
                      referrals: agentReferrals,
                      selectedAgent: agent,
                      activeTab: "referrals",
                    }, // Передаем отфильтрованные данные и текущего агента
                  });
                }}
              >
                <AgentInfo>
                  {agent.avatar ? (
                    <img
                      src={
                        agent.avatar
                          ? `data:image/jpeg;base64,${agent.avatar}`
                          : "default_avatar_path"
                      }
                      alt={agent.username}
                      style={{ width: 50, height: 50, borderRadius: "50%" }}
                    />
                  ) : (
                    <div
                      className="_media_iud9y_8"
                      style={{
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        style={{ borderRadius: "50%", width: 50 }}
                        className="_avatar_iud9y_19"
                        src={`https://ui-avatars.com/api/?name=${agent.username}&background=random&color=fff`}
                        loading="lazy"
                        alt="Avatar"
                      />
                    </div>
                  )}

                  <AgentName>
                    <div>{agent.username} &nbsp;</div>

                    {/* Если агент не скрыт по условию, показываем уровни */}
                    {!isFilteredAgent2 && (
                      <>
                        <LevelInfo>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            Lvl 1 &nbsp;
                            <PersonIcon
                              style={{ fontSize: "1rem", color: "#4BB543" }}
                            />
                            &nbsp;
                            {agent.lvl2}&nbsp;
                          </div>
                        </LevelInfo>
                      </>
                    )}
                    <LevelInfo>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        Refs &nbsp;
                        <PersonIcon
                          style={{ fontSize: "1rem", color: "#4BB543" }}
                        />
                        &nbsp;
                        {agent.total_active_count}&nbsp;
                      </div>
                    </LevelInfo>
                  </AgentName>
                </AgentInfo>
                <div>
                  {agent.level != 2 ? (
                    <Earnings>$ {agent.total_earned * 0.3}</Earnings>
                  ) : (
                    <Earnings>$ {agent.total_earned * 0.2}</Earnings>
                  )}
                </div>
              </AgentCard>
            );
          })}
          {user.user_id == referrals.user_id ? (
            <RefLinkButton
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                const shareLink = `https://t.me/share/url?url=https://t.me/friendsdaobot?start=${user.user_id}`;
                window.open(shareLink, "_blank");
              }}
            >
              <LinkIcon
                fontSize="large"
                style={{
                  transform: "rotate(-45deg)",
                  cursor: "pointer",
                }}
              />
              Invite more
            </RefLinkButton>
          ) : (
            <></>
          )}
        </AgentsList>
      )}

      {activeTab === "referrals" && (
        <AgentsList>
          {isContractLoading && Spinner}
          {contracts?.map((contract, index) => (
            <AgentCard key={index}>
              <AgentInfo>
                {contract.avatar ? (
                  <img
                    src={
                      contract.avatar
                        ? `data:image/jpeg;base64,${contract.avatar}`
                        : "default_avatar_path"
                    }
                    alt={contract.username}
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                ) : (
                  <div
                    className="_media_iud9y_8"
                    style={{
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      style={{ borderRadius: "50%", width: 50 }}
                      className="_avatar_iud9y_19"
                      src={`https://ui-avatars.com/api/?name=${contract.title}&background=random&color=fff`}
                      loading="lazy"
                      alt="Avatar"
                    />
                  </div>
                )}
                <AgentName>
                  <div
                    style={{
                      fontSize: "16px",
                      fontFamily: "SF medium, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {contract?.title}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontFamily: "SF medium, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {contract?.active_count} refs (max: {contract?.limit})
                  </div>
                  <LevelInfo>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: "#000000",
                        opacity: " 0.6",
                        lineHeight: "14px",
                        fontWeight: "bold",
                        fontSize: 12,
                        fontStyle: "normal  ",
                      }}
                    >
                      {contract?.level - user.level == 1 && (
                        <>$ {contract.price * 0.3} each &nbsp;</>
                      )}
                      {contract?.level - user.level == 2 && (
                        <>$ {contract.price * 0.2} each &nbsp;</>
                      )}
                      {contract?.level - user.level == 0 && (
                        <>$ {contract.price * 0.5} each &nbsp;</>
                      )}
                    </div>
                  </LevelInfo>
                </AgentName>
              </AgentInfo>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                {contract?.level - user.level == 1 && (
                  <Earnings>
                    {(contract?.active_count * contract.price * 0.3).toFixed(3)}
                    $ (60%)
                  </Earnings>
                )}{" "}
                {contract?.level - user.level == 2 && (
                  <Earnings>
                    {(contract?.active_count * contract.price * 0.2).toFixed(3)}
                    $ (40%)
                  </Earnings>
                )}
                {contract?.level - user.level == 0 && (
                  <Earnings>
                    {contract?.active_count * contract.price * 0.5}$
                  </Earnings>
                )}
                <AgentName
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontFamily: "SF medium, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    {contract?.total_active_count} / {contract?.required_count}{" "}
                    goal
                  </div>
                </AgentName>
              </div>
              <CustomButton
                onClick={() => {
                  const inviteLink = `${contract.url}`;
                  navigator.clipboard
                    .writeText(inviteLink)
                    .then(() => {
                      setCopyMessage(true);
                      setTimeout(() => setCopyMessage(false), 5000);
                    })
                    .catch((err) => {
                      console.error("Failed to copy: ", err);
                    });
                }}
              >
                🔗
              </CustomButton>
            </AgentCard>
          ))}
          {copyMessage && <CopyAlert>Invite link was copied!</CopyAlert>}
          <div
            style={{
              fontSize: "14px",
              fontFamily: "SF normal, sans-serif",
              fontWeight: 400,
              color: "#AAA",
            }}
          >
            15+ apps coming soon
          </div>
        </AgentsList>
      )}
    </div>
  );
}
