import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, generateHash } from "../Helpers/Api";

export const ContractsContext = createContext();

export const ContractsProvider = ({ children }) => {
  const [contracts, setContracts] = useState([]);
  const [isContractLoading, setIsContractLaoading] = useState(false);
  const fetchUserContracts = async (telegramId) => {
    setIsContractLaoading(true);
    const { signature, timestamp } = await generateHash(telegramId);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/get_contracts/`,
        { user_id: telegramId },
        {
          headers: {
            "X-Signature": signature, // Підпис
            "X-Timestamp": timestamp, // Час
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setContracts(response.data);
        window.Telegram.WebApp.HapticFeedback.impactOccurred("heavy");
        setIsContractLaoading(false);
        return response.data;
      } else {
        console.error("Error fetching Referrals:", response.data.message);
        setIsContractLaoading(false);
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch Referrals:", error);
      setIsContractLaoading(false);
      return null;
    }
  };

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        setContracts,
        fetchUserContracts,
        isContractLoading,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};
