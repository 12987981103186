// themes.js
import { createTheme } from '@mui/material';

const theme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
        // Other component customizations
    },
});

export default theme;
