export const API_BASE_URL = "https://mini-app-back.friendsdao.com/api";
export const telegram_id = "874423521";
const BOT_TOKEN = process.env.REACT_APP_BOT_TOKEN;
// Генерація хешу (як описано раніше)
export async function generateHash(userId) {
  const timestamp = Math.floor(Date.now() / 1000); // Генерація таймстампу (в секундах)
  const message = `${userId}:${timestamp}`; // Формування повідомлення
  const encoder = new TextEncoder();
  const keyData = encoder.encode(BOT_TOKEN); // Використовуємо бот-токен
  const messageData = encoder.encode(message);

  const key = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  );

  const signatureBuffer = await crypto.subtle.sign("HMAC", key, messageData);
  const signature = Array.from(new Uint8Array(signatureBuffer))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return { signature, timestamp };
}
