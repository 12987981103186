import React, { useEffect, useContext, useState, useRef } from "react";
import axios from "axios";
import { UserContext } from "../Context/UserContext";
import { ChannelsContext } from "../Context/ChannelsContext";
import { ReferralsContext } from "../Context/ReferralsContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Spinner } from "../icons/Spinner";
import { ContractsContext } from "../Context/ContractsContext";

const PreLoad = ({ telegramId }) => {
  const navigate = useNavigate();
  const { user, fetchUser } = useContext(UserContext);
  const { setChannels, fetchChannels } = useContext(ChannelsContext);
  const { referrals, fetchReferrals } = useContext(ReferralsContext);
  const { contracts, fetchUserContracts } = useContext(ContractsContext);
  const hasFetchedData = useRef(false); // Використовується для запобігання повторному запиту

  useEffect(() => {
    if (hasFetchedData.current) return; // Якщо дані вже завантажено, припиняємо

    const loadData = async () => {
      await fetchAllData();
    };

    loadData();
  }, []);

  const fetchAllData = async () => {
    try {
      const fetchedUser = await fetchUser(telegramId); // Отримуємо дані користувача
      const fetchedReferrals = await fetchReferrals(telegramId);
      const fetchedContrats = await fetchUserContracts(telegramId);
      if (fetchedUser && fetchedReferrals) {
        // Если данные пользователя и рефералов получены, сохраняем их в состоянии
        hasFetchedData.current = true;

        // Передаем данные через navigate
        navigate("/home", {
          state: {
            user: fetchedUser,
            referrals: fetchedReferrals,
            activeTab: "referrals",
          }, // Передаем данные правильно
        });
      } else {
        throw new Error("User not found or referrals not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <ImageContainer></ImageContainer>
      <Headline></Headline>
      <Spinner />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--tg-theme-bg-color);
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Black background */
`;

const ImageContainer = styled.div`
  margin-top: 20px;
  img {
    width: 300px; /* Adjust size as needed */
    height: auto;
  }
`;

const Headline = styled.h1`
  color: var(--tg-theme-text-color);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 20px 20px;
`;

const StartButton = styled.button`
  background: linear-gradient(90deg, #00ffa3 0%, #00b6ff 100%);
  width: 90vw;
  color: white;
  font-size: 18px;
  padding: 10px 40px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  &:hover {
    background: linear-gradient(90deg, #00d98a 0%, #00a5e0 100%);
  }
`;

export default PreLoad;
