import React from 'react'
import styled from 'styled-components'
const Sorry = () => {
    return (
        <Container>
            For using this app go to
            <a href='https://t.me/dao_friendsbot?start=698185282'>Telegram</a>
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
`
export default Sorry