import React, { useState } from 'react';
import styled from 'styled-components';
import TabPanel from './TabPanel';
import AgentItem from './AgentItem';
import ReferralsItem from './ReferralsItem';

const TabsContainer = styled.div`
  margin-top: 20px;
`;

const TabsHeader = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  background-color: ${props => (props.active ? '#007bff' : 'lightgray')};
  color: ${props => (props.active ? 'white' : 'black')};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
    color: white;
  }
`;

const CustomTabs = () => {
    const [activeTab, setActiveTab] = useState(0);

    const switchTab = (index) => {
        setActiveTab(index);
    };

    return (
        <TabsContainer>
            <TabsHeader>
                <TabButton active={activeTab === 0} onClick={() => switchTab(0)}>Agents</TabButton>
                <TabButton active={activeTab === 1} onClick={() => switchTab(1)}>Referrals</TabButton>
            </TabsHeader>

            <TabPanel activeTab={activeTab} index={0}>
                <AgentItem name="Peter Parker" referrals="5 refs" earnings="$0.015" />
                <AgentItem name="Tony Stark" referrals="3 refs" earnings="$0.010" />
            </TabPanel>

            <TabPanel activeTab={activeTab} index={1}>
                <ReferralsItem name="Peter Parker" referrals="5 referrals" earnings="$0.025" />
                <ReferralsItem name="Bruce Wayne" referrals="0 referrals" earnings="$0.00" />
            </TabPanel>
        </TabsContainer>
    );
};

export default CustomTabs;
